
















































































































































import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CarSippMappingPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-sipp-mapping-page-otel-log';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import SippItemModel from '@/modules/cars/modules/sipp/models/sipp-item.model';
import { Splitpanes, Pane } from 'splitpanes';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import _ from 'lodash';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import CarsSwitcher from '@/modules/cars/components/cars-switcher.vue';
import CarNote from '@/modules/cars/components/car-note.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import SippItem from './sipp-item.vue';
import SippService, { SippServiceS } from '../sipp.service';
import SippActions from './sipp-actions.vue';
import SippHistoryBar from './sipp-history/sipp-history-bar.vue';

export interface ISippTableHead {
    value: string;
    key: string;
    scale: number,
    minWidth: number;
}

@Component({
    components: {
        ModalWrapper,
        SippItem,
        SippActions,
        LoaderWrapper,
        SippHistoryBar,
        Splitpanes,
        Pane,
        CarsSwitcher,
        CarNote,
        CustomSwitch,
    },
})
export default class Sipp extends CarSippMappingPageOtelLogs {
    @Inject(UserServiceS) public userService!: UserService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    isUpdating: boolean = false;
    hoverColumn: string = '';
    isDragging: boolean = false;
    isHistoryOpen: boolean = false;
    infoTooltip: boolean = false;
    tooltipFields = ['customerSippCode',
        'fnSippCode',
        'categoryName'];
    existsTooltips: any = {
        customerSippCode: false,
        fnSippCode: false,
        categoryName: false,
    };

    columnsData: ISippTableHead[] = [{
        key: 'carDescription',
        value: 'Car name',
        scale: 9,
        minWidth: 10,
    }, {
        key: 'provider',
        value: 'Provider',
        scale: 8,
        minWidth: 5,
    }, {
        key: 'brand',
        value: 'Brand',
        scale: 6,
        minWidth: 4,
    }, {
        key: 'countryCode',
        value: 'POS',
        scale: 6,
        minWidth: 5,
    }, {
        key: 'countryName',
        value: 'Country code',
        scale: 6,
        minWidth: 8,
    }, {
        key: 'carCategory',
        value: 'Car category',
        scale: 8,
        minWidth: 10,
    }, {
        key: 'categoryName',
        value: 'Normalized Category',
        scale: 8,
        minWidth: 8,
    }, {
        key: 'fuelType',
        value: 'Fuel Type',
        scale: 8,
        minWidth: 8,
    }, {
        key: 'vehicleType',
        value: 'Is van',
        scale: 6,
        minWidth: 5,
    }, {
        key: 'transmission',
        value: 'Vehicle Transmission',
        scale: 8,
        minWidth: 8,
    }, {
        key: 'fnSippCode',
        value: 'Standard SIPP code',
        scale: 9.6,
        minWidth: 8,
    }, {
        key: 'customerSippCode',
        value: 'Client SIPP code',
        scale: 9.6,
        minWidth: 8,
    }];

    handleCompsetFiltersChange() {
        this.sippService.storeState.compsetFilters = !this.sippService.storeState.compsetFilters;
    }

    created() {
        this.sippService.storeState.isPopupClose = false;
        this.adjustColumnScales();
    }

    get columnGrid() {
        return this.columns.map(column => `${column.scale}%`).join(' ');
    }

    reachEnd() {
        this.sippService.loadMoreData();
    }

    get columns() {
        return this.columnsData;
    }

    set columns(value: ISippTableHead[]) {
        this.columnsData = value;
    }

    get hoverKey() {
        return this.hoverColumn;
    }

    set hoverKey(value: string) {
        this.hoverColumn = value;
    }

    get useParentSippCode(): boolean {
        return this.carsFiltersService.useParentSippCode;
    }

    get isChildChain(): boolean {
        return this.carsSharedService.isChildChain;
    }

    get isChangesDone() {
        return this.sippService.storeState.diffDocuments.length;
    }

    get isInvalidItems() {
        const invalidItems = Object.keys(this.sippService.storeState.invalidItems).filter(item => this.sippService.storeState.invalidItems[item]);
        return !!invalidItems.length;
    }

    async handleUpdate() {
        if (this.sippService.storeState.diffDocuments.length === 0) {
            return;
        }
        this.isUpdating = true;
        await this.sippService.updateSippCode();
        this.isUpdating = false;
    }

    handleClose() {
        this.$router.back();
        this.sippService.logVersion = null;
        this.sippService.logsChanges = null;
        this.sippService.documentsPreview = null;
        this.sippService.showChangesOnly = false;
        this.sippService.diffDocuments = [];
        this.sippService.storeState.isPopupClose = true;
    }

    resetFilters() {
        const { keys } = this.sippSettingsService;

        this.sippSettingsService.storeState.isFilterReseted = true;

        keys.forEach(item => {
            (this.sippSettingsService as any)[item] = this.sippService.storeState.defaultFilters[item];
        });

        this.sippSettingsService.storeState.isFilterReseted = false;
    }

    handleOpenHistoryBar() {
        this.isHistoryOpen = !this.isHistoryOpen;
    }

    handleCloseHistoryBar() {
        this.isHistoryOpen = !this.isHistoryOpen;
        this.sippService.logVersion = null;
        this.sippService.logsChanges = null;
        this.sippService.documentsPreview = null;
        this.sippService.showChangesOnly = false;
    }

    handleResized(data: any) {
        this.isDragging = false;
        data.forEach((item: any, key: number) => {
            this.columnsData[key].scale = item.size;
        });

        this.columns = this.columnsData;
        this.isDragging = false;
    }

    handleResizing() {
        this.isDragging = true;
    }

    handleHover(key: string) {
        if (this.isDragging) {
            return;
        }
        const splitters = document.getElementsByClassName('splitpanes__splitter') as HTMLCollectionOf<HTMLElement>;
        const indexColumn = this.columns.findIndex(item => item.key === key);
        Array.from(splitters).forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.style.display = 'none';
        });
        if (splitters[indexColumn]) {
            splitters[indexColumn].style.display = 'block';
        }
        if (splitters[indexColumn - 1]) {
            splitters[indexColumn - 1].style.display = 'block';
        }
        this.hoverKey = key;
    }

    handleLeave() {
        if (this.isDragging) {
            return;
        }
        const splitters = document.getElementsByClassName('splitpanes__splitter') as HTMLCollectionOf<HTMLElement>;
        Array.from(splitters).forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.style.display = 'none';
        });
        this.hoverKey = '';
    }

    get showChangesOnly() {
        return this.sippService.showChangesOnly;
    }

    async handleDownloadCLick() {
        await this.sippService.download();
    }

    get items() {
        const {
            items,
            logsChanges,
            documentsPreview,
            showChangesOnly,
        } = this.sippService;
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;

        const diffDocs = this.sippService.diffDocuments;

        if (!items || !items.length) {
            return null;
        }
        const jsonData = JSON.stringify(items);
        let data: SippItemModel[] = JSON.parse(jsonData);
        data = data.map(item => ({ ...item, provider: _.get(carProvidersDisplayNamesMap, item.provider) }));

        if (showChangesOnly && documentsPreview) {
            data = documentsPreview;
        }

        if (logsChanges) {
            logsChanges.forEach(item => {
                const index = data.findIndex(doc => item.id === doc.id);
                if (index !== -1) {
                    data[index].customerSippCode = item.currentCode;
                    data[index].categoryName = item.currentCategoryName;
                    data[index].vehicleType = item.currentVehicleType;
                    data[index].fuelType = item.currentFuelType;
                }
            });
        }
        if (documentsPreview) {
            documentsPreview.forEach(item => {
                const index = data.findIndex(doc => item.id === doc.id);
                if (index !== -1) {
                    data[index].customerSippCode = item.customerSippCode;
                    data[index].categoryName = item.categoryName;
                    data[index].vehicleType = item.vehicleType;
                    data[index].fuelType = item.fuelType;
                }
            });
        }

        if (diffDocs && diffDocs.length) {
            diffDocs.forEach(item => {
                // eslint-disable-next-line no-underscore-dangle
                const index = data.findIndex(doc => item._id === doc.id);
                if (index !== -1) {
                    data[index].itemDiff = {
                        customerSippCode: item.new_customer_sipp_code || '',
                        categoryName: item.new_customer_normalized_category || '',
                        fuelType: item.new_fuel_type || '',
                        vehicleType: item.new_vehicle_type || '',
                    };
                }
            });
        }

        return data;
    }

    get count() {
        return this.sippService.totalCountOfSippRecords;
    }

    get isReadonly() {
        return !this.carsSharedService.sippMapManagement;
    }

    get isChildChainAndUseParentSippCode(): boolean {
        return this.carsSharedService.isChildChainAndUseParentSippCode;
    }

    get parentChainName() {
        return this.carsSharedService.parentChainName;
    }

    // The general scale not equal approximately 100 causes broken table layout
    // and we have no guarantee scales will be correct.
    // So, here is a safeguard which calculates the general scale and in case of
    // deficit gains the columns that the general size to be 100.
    private adjustColumnScales() {
        const vergeToGain = 99.5;
        const totalScale = this.columnsData.reduce((sum, { scale }) => sum + scale, 0);
        if (totalScale < vergeToGain) {
            const valueToSum = (100 - totalScale) / this.columnsData.length;
            this.columnsData = this.columnsData.map(col => ({ ...col, scale: col.scale + valueToSum }));
        }
    }

    elements() {
        const el = document.getElementsByClassName('focus_element');
        return [...el];
    }

    findIndex(target: EventTarget) {
        return this.elements().findIndex(el => el === target);
    }

    moveFocus(index: number) {
        const el = this.elements()[index] as HTMLElement;
        if (el) {
            el.focus({ preventScroll: false });
            if (el.tagName === 'INPUT') {
                const element = this.elements()[index] as HTMLInputElement;
                // solution of lazy loading not working
                el.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                });
                element.select();
            }
        }
    }

    moveNext(event: Event) {
        if (event && event.target && this.sippService.storeState.loading) {
            const index = this.findIndex(event.target);
            this.moveFocus(index + 1);
        }
    }
    movePrev(event: Event) {
        if (event && event.target && this.sippService.storeState.loading) {
            const index = this.findIndex(event.target);
            this.moveFocus(index - 1);
        }
    }
}
