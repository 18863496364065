import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SippService, { SippServiceS } from '../../sipp/sipp.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarSippMappingPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(SippServiceS) protected sippService!: SippService;
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(CarSippMappingPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', CarSippMappingPageOtelLogs.spanPrefix);
        this.endSpanWhenLoadingFinished();
    }

    @Watch('carsSharedService.carsStoreState.settings.isVansSippCodePopup')
    isVansChanged() {
        const isSpanExists = this.isSpanExists(CarSippMappingPageOtelLogs.spanPrefix);
        if (!isSpanExists) {
            this.postfix = `isVans=${this.carsSharedService.carsStoreState.settings.isVansSippCodePopup}`;
            this.startSpan(CarSippMappingPageOtelLogs.spanPrefix);
            this.addEvent('Vans switched', CarSippMappingPageOtelLogs.spanPrefix);
            this.endSpanWhenLoadingFinished();
        }
    }

    endSpanWhenLoadingFinished() {
        this.sippService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', CarSippMappingPageOtelLogs.spanPrefix);
                this.endSpan(CarSippMappingPageOtelLogs.spanPrefix, { sendLogs: true });
            });
    }
}
