









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import _ from 'lodash';
import { COUNTRIES_ANY, PROVIDER_ANY, PROVIDER_NONE } from '@/modules/cars/constants/car-filter-types.constant';

@Component({
    components: { CustomSelect },
})
export default class CarProviderFilter extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    get items(): Item[] {
        const { compsetFilters } = this.sippService.storeState;
        const { providers } = this.sippService.filters;
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;
        const { countryName } = this.sippSettingsService.storeState.settings;
        let filteredProviders = providers;
        const { aggregateAllowedVendorsPerCountry } = this.carsFiltersService;
        if (compsetFilters && filteredProviders?.length && countryName && countryName !== COUNTRIES_ANY) {
            const selectedCountryName = Array.isArray(countryName) ? countryName[0] : countryName;
            const allowedProviders = Object.keys(aggregateAllowedVendorsPerCountry[selectedCountryName] || { });
            const brandIndex = allowedProviders.findIndex(element => element.toLocaleLowerCase() === 'brand');
            if (brandIndex !== -1) {
                allowedProviders.splice(brandIndex, 1);
                filteredProviders = [PROVIDER_ANY, PROVIDER_NONE, ...allowedProviders, ...aggregateAllowedVendorsPerCountry[selectedCountryName].Brand];
            }
        }

        if (!filteredProviders) {
            return [{ name: 'Any', value: 'Any' }];
        }

        if (!this.currentValue || !filteredProviders.find(carClass => carClass === this.currentValue)) {
            const [defaultPos] = filteredProviders;
            this.currentValue = defaultPos;
        }
        return [
            ...filteredProviders.map(value => ({
                name: _.get(carProvidersDisplayNamesMap, value) || value,
                value,
            })),
        ];
    }

    get currentValue() {
        return this.sippSettingsService.providersValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.providersValue = value;
        }
    }
}
